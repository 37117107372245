<template>
    <div class="w-full h-20 bg-white z-10 p-4 flex-grow">      
        <span class="font-bold text-4xl align-middle inline-block">{{ title }}</span>
        <span v-if="mail" class="font-bold text-xl align-top float-right hidden sm:inline-block">Willkommen,<br>{{ mail.split('@')[0] }}</span>
        <font-awesome-icon icon="bars" class="float-right inline-block sm:hidden text-3xl text-primary cursor-pointer mt-1" @click="$emit('toggleMenu')"/>
    </div>
</template>
  
<script>
// @ is an alias to /src
export default {
    name: 'Header',
    props: ['title','mail'],

}
</script>
  